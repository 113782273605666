import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

interface BookProps {
  id: number
  title: string
  desc: string
  pub_date: string
  genre: string
  img: any
}

export default function useBlogPosts() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              desc
              genre
              id
              title
              imgAlt
              img {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    transformOptions: { cropFocus: CENTER }
                    aspectRatio: 0.7
                    quality: 10
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const { allMarkdownRemark } = data

  const books = () => {
    const items = allMarkdownRemark.edges
    // original
    return items.map(({ node }: any) => (
      <article key={node.frontmatter.id} className="books-card">
        {console.log(node)}
        <div className="heading-box">
          <h3 className="heading-scndry">{node.frontmatter.title}</h3>
          <span className="p-main">{node.frontmatter.genre}</span>
        </div>
        <GatsbyImage
          image={getImage(node.frontmatter.img)!}
          alt={node.frontmatter.imgAlt}
          className="book-img-mb"
          objectFit="contain"
        />
        <p className="p-main">{node.frontmatter.desc}</p>
      </article>
    ))

    // return (
    //   <>
    //     <GatsbyImage
    //         image={getImage(items[0].node.frontmatter.img)!}
    //         alt={items[0].node.frontmatter.imgAlt}
    //         className="book-img-mb"
    //         objectFit="contain"
    //       />
    //     <GatsbyImage
    //         image={getImage(items[1].node.frontmatter.img)!}
    //         alt={items[1].node.frontmatter.imgAlt}
    //         className="book-img-mb"
    //         objectFit="contain"
    //       />
    //     <article key={items[0].node.frontmatter.id} className="books-card">
    //       <div className="heading-box">
    //         <h3 className="heading-scndry">
    //           {items[0].node.frontmatter.title}
    //         </h3>
    //         <span className="p-main">{items[0].node.frontmatter.genre}</span>
    //       </div>

    //       <StaticImage
    //         src="../assets/images/hvitum_kjol.png"
    //         alt={items[0].node.frontmatter.imgAlt}
    //         as="div"
    //         loading="eager"
    //         className="book-img-mb"
    //         placeholder="blurred"
    //         layout="fixed"
    //         height={400}
    //       />
    //       <p className="p-main">{items[0].node.frontmatter.desc}</p>
    //     </article>
    //     <article key={items[1].node.frontmatter.id} className="books-card">
    //       <div className="heading-box">
    //         <h3 className="heading-scndry">
    //           {items[1].node.frontmatter.title}
    //         </h3>
    //         <span className="p-main">{items[1].node.frontmatter.genre}</span>
    //       </div>

    //       <StaticImage
    //         src="../assets/images/nafna.png"
    //         alt={items[0].node.frontmatter.imgAlt}
    //         as="div"
    //         loading="eager"
    //         className="book-img-mb"
    //         placeholder="blurred"
    //         layout="fixed"
    //         height={400}
    //       />
    //       <p className="p-main">{items[1].node.frontmatter.desc}</p>
    //     </article>
    //   </>
    // )
  }

  return {
    books,
  }
}
