import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import ReadMoreArrowBtn from "../components/buttons/readMoreArrowBtn"

interface BloggPostProps {
  id: number
  title: string
  excerpt: string
  uri: string
  date: string
}

export default function useBlogPosts() {
  const data = useStaticQuery(graphql`
    {
      allWpPost(sort: { order: DESC, fields: [date] }) {
        nodes {
          title
          content
          excerpt
          id
          uri
          slug
          date(formatString: "DD MMM YYYY")
        }
      }
    }
  `)

  const { allWpPost } = data

  // Function that returns all the posts

  // To do: Implement a loading state feature

  const [allBloggItems, setAllBloggItems] = useState([])

  useEffect(() => {
    setAllBloggItems(allWpPost.nodes)
  }, [setAllBloggItems])

  const allBloggPosts = () => {
    return allBloggItems.map(
      ({ id, title, excerpt, uri, date }: BloggPostProps) => {
        return (
          <article key={id} className="blogg-card">
            <Link className="heading-link" to={uri}>
              <h2 className="heading-scndry">{title}</h2>
            </Link>
            <time className="date-label">{date}</time>
            <div
              className="p-main"
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
            <ReadMoreArrowBtn uri={uri} label={"Lesa meira"} />
          </article>
        )
      }
    )
  }

  // Function that returns the first five blog posts

  // To do: Implement a loading state feature

  const [firstFiveItems, setFirstFiveItems] = useState([])

  useEffect(() => {
    setFirstFiveItems(allWpPost.nodes.slice(0, 5))
  }, [setFirstFiveItems])

  const firstFivePosts = () => {
    return firstFiveItems.map(
      ({ id, title, excerpt, uri, date }: BloggPostProps) => {
        return (
          <article key={id} className="blogg-card">
            <Link className="heading-link" to={uri}>
              <h2 className="heading-scndry">{title}</h2>
            </Link>
            <time className="date-label">{date}</time>
            <div
              className="p-main"
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
            <ReadMoreArrowBtn uri={uri} label={"Lesa meira"} />
          </article>
        )
      }
    )
  }

  return {
    firstFivePosts,
    allBloggPosts,
  }
}
