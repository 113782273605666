import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import useBlogPosts from "../../hooks/useBlogPosts"
import useBooks from "../../hooks/useBooks"
import Seo from "../../components/seo"

const home = () => {
  const { firstFivePosts } = useBlogPosts()
  const { books } = useBooks()
  return (
    <>
      <Seo title="Forsíða" />

      {/* Intro section */}

      <section id="intro-section">
        <div id="dark-background"></div>
        <div className="content-wrapper">
          <div className="intro-txt">
            <h2 className="heading-primary">Velkomin/nn</h2>
            <p className="p-main-large">
              Ég heiti Rósa Ólöf og hér finnur þú þankaganga, bækur og annað sem
              við kemur mínum hugarheimi.
            </p>
          </div>
          <StaticImage
            style={{ position: "absolute" }}
            src="../../assets/images/Rose_mb.png"
            alt="Image of a rose"
            as="div"
            loading="eager"
            className="intro-img-container-mb"
            imgClassName="intro-hero-img"
            placeholder="blurred"
            layout="fixed"
            height={400}
          />
          <StaticImage
            src="../../assets/images/Rose_dp.png"
            alt="Image of a rose in a vase"
            as="div"
            loading="eager"
            className="intro-img-container-dp"
            imgClassName="intro-hero-img"
            placeholder="blurred"
            layout="fixed"
            height={550}
          />
        </div>
      </section>

      {/* Blogg section */}

      <section id="blogg-section">
        <div className="heading-box">
          <h2 className="heading-primary">Blogg</h2>
          <span className="sub-heading">Nýjast</span>
        </div>
        <div className="blogg-post-container">{firstFivePosts()}</div>
      </section>

      {/* Bækur section */}

      <section id="books-section">
        <h2 className="heading-primary">Bækur</h2>
        <div className="books-post-container">{books()}</div>
      </section>
    </>
  )
}

export default home
